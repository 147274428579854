import { AccordionSummary, Grid, Stack } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import GameFormatIcon from './GameFormatIcon';
import TypographySecondary from './TypographySecondary';
import moment from 'moment';
import PlayerNameAndFactions from './PlayerNameAndFactions';
import { Match, MatchPlayer } from '../graphql/__generated__/graphql';
import Chips from './Chips';

interface Props {
  myPlayer: MatchPlayer
  adversaryPlayer?: MatchPlayer
  match: Match
}

const MatchAccordionSummary = ({myPlayer, adversaryPlayer, match}: Props) => {
  const date = moment(match?.gameStartTimeUtc).format("DD/MM/YYYY HH:mm")

  const gameMode = match.gameMode?.toString()
  const gameType = match.gameType?.toString()
  const gameFormat = match.gameFormat?.toString()

  const chipsHashs = [
    { label: gameMode, show: gameMode !== "Constructed", capitalize: true },
    { label: gameType, show: gameType !== "Tutorial", capitalize: true },
    { label: gameFormat, capitalize: true }
  ]

  return (
    <>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Grid container sx={{mr: {sm: "16px"}}} spacing={"12px"}>
          <Grid item xs={12} sm={"auto"} display={"center"} justifyContent={"center"} alignItems={"center"}>
            <Stack 
              direction={"row"} 
              spacing={0.5} 
              justifyContent={"center"} 
              alignItems={"center"}
            >
              <PlayerNameAndFactions player={myPlayer} nameBold />

              <GameFormatIcon adversaryPlayer={adversaryPlayer} format={match.gameFormat?.toString()} />

              <PlayerNameAndFactions player={adversaryPlayer} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm>
            <Grid container spacing={"12px"}>
              <Grid
                item
                xs={12}
                sm={"auto"}
                display={"flex"}
                sx={{justifyContent: {xs: "center", sm: "flex-start"}, fontWeight: 'bold'}}
                alignItems={"center"}
              >
                <Chips chipsHashs={chipsHashs} />
              </Grid>
              <Grid 
                item 
                xs={12} 
                sm
                display={"flex"} 
                sx={{justifyContent: {xs: "center", sm: "flex-end"}}}
                alignItems={"center"}
              >
                <TypographySecondary>
                  {date}
                </TypographySecondary>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
    </>
  )
}

export default MatchAccordionSummary
