import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ISO8601DateTime: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  gameName?: Maybe<Scalars['String']['output']>;
  matches?: Maybe<Array<Match>>;
  riotId?: Maybe<Scalars['String']['output']>;
  tagLine?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type Match = {
  __typename?: 'Match';
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dataVersion?: Maybe<Scalars['String']['output']>;
  gameFormat?: Maybe<Scalars['String']['output']>;
  gameMode?: Maybe<Scalars['String']['output']>;
  gameStartTimeUtc?: Maybe<Scalars['ISO8601DateTime']['output']>;
  gameType?: Maybe<Scalars['String']['output']>;
  gameVersion?: Maybe<Scalars['String']['output']>;
  matchPlayers?: Maybe<Array<MatchPlayer>>;
  participants?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Scalars['String']['output']>;
  riotMatchId?: Maybe<Scalars['String']['output']>;
  totalTurnCount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type MatchPlayer = {
  __typename?: 'MatchPlayer';
  account?: Maybe<Account>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  deckCode?: Maybe<Scalars['String']['output']>;
  deckId?: Maybe<Scalars['String']['output']>;
  factions?: Maybe<Array<Scalars['String']['output']>>;
  gameOutcome?: Maybe<Scalars['String']['output']>;
  match?: Maybe<Match>;
  orderOfPlay?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** Return an account */
  account?: Maybe<Account>;
};


export type QueryAccountArgs = {
  region: Scalars['String']['input'];
  riotId: Scalars['String']['input'];
};

export type GetAccountQueryVariables = Exact<{
  region: Scalars['String']['input'];
  riotId: Scalars['String']['input'];
}>;


export type GetAccountQuery = { __typename?: 'Query', account?: { __typename?: 'Account', riotId?: string | null, gameName?: string | null, tagLine?: string | null, matches?: Array<{ __typename?: 'Match', riotMatchId?: string | null, gameMode?: string | null, gameType?: string | null, gameFormat?: string | null, gameStartTimeUtc?: any | null, totalTurnCount?: number | null, matchPlayers?: Array<{ __typename?: 'MatchPlayer', deckCode?: string | null, factions?: Array<string> | null, gameOutcome?: string | null, orderOfPlay?: number | null, account?: { __typename?: 'Account', riotId?: string | null, gameName?: string | null, tagLine?: string | null } | null }> | null }> | null } | null };


export const GetAccountDocument = gql`
    query getAccount($region: String!, $riotId: String!) {
  account(region: $region, riotId: $riotId) {
    riotId
    gameName
    tagLine
    matches {
      riotMatchId
      gameMode
      gameType
      gameFormat
      gameStartTimeUtc
      totalTurnCount
      matchPlayers {
        deckCode
        factions
        gameOutcome
        orderOfPlay
        account {
          riotId
          gameName
          tagLine
        }
      }
    }
  }
}
    `;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      region: // value for 'region'
 *      riotId: // value for 'riotId'
 *   },
 * });
 */
export function useGetAccountQuery(baseOptions: Apollo.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables> & ({ variables: GetAccountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
      }
export function useGetAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
        }
export function useGetAccountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
        }
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountSuspenseQueryHookResult = ReturnType<typeof useGetAccountSuspenseQuery>;
export type GetAccountQueryResult = Apollo.QueryResult<GetAccountQuery, GetAccountQueryVariables>;