import { useContext } from "react";
import { Match } from "../graphql/__generated__/graphql"
import TypographySecondary from "./TypographySecondary"
import { useRollbar  } from '@rollbar/react'; 
import { SearchContext, SearchContextValues } from "../contexts/SearchContext";
import { useTranslation } from 'react-i18next';

interface Props {
  matches?: Match[]
}

const NoRecentMatches = ({matches}: Props) => {
  const { region, riotId } = useContext(SearchContext) as SearchContextValues

  const rollbar = useRollbar();
  const { t } = useTranslation()

  if (matches && matches.length > 0) {
    return null
  }

  rollbar.info("No Recent Matches", { region: region, riotId: riotId })

  return (
    <>
      <TypographySecondary textAlign={"center"} mb={2}>
        {t("This player has no recent matches")}
      </TypographySecondary>
    </>
  )
}

export default NoRecentMatches
