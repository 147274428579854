import { Stack, Typography } from "@mui/material"
import FactionImagesStack from "./FactionImagesStack"
import { Account, MatchPlayer } from "../graphql/__generated__/graphql"

interface Props {
  player?: MatchPlayer
  nameBold?: boolean
}

const PlayerNameAndFactions = ({player, nameBold = false}: Props) => {
  if (!player?.account) {
    return null
  }

  return (
    <>
      <Stack direction={"row"} flexWrap={"wrap"} justifyContent="center" alignItems="center" spacing={0.5}>
        <Typography fontSize={"12.5px"} fontWeight={nameBold ? "bold" : ""}>
          {player.account?.gameName}
        </Typography>

        <FactionImagesStack player={player}/>
      </Stack>
    </>
  )
}

export default PlayerNameAndFactions
