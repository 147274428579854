import { Chip as MaterialChip } from "@mui/material"
import { useTranslation } from 'react-i18next';

interface Props {
  label?: string
  show?: boolean
  capitalize?: boolean
}

const Chip = ({label, show = true, capitalize = false}: Props) => {
  const { t } = useTranslation()

  if (!show || !label) {
    return null
  }

  return (
    <>
      <MaterialChip label={t(label)} sx={{textTransform: capitalize ? 'capitalize' : 'none'}} />
    </>
  )
}

export default Chip
