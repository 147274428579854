import { Button, Typography } from "@mui/material"
import SendIcon from '@mui/icons-material/Send';
import { useContext } from "react";
import { SearchContext, SearchContextValues } from "../contexts/SearchContext";
import { MatchPlayer } from "../graphql/__generated__/graphql";
import { useRollbar  } from '@rollbar/react'; 
import { useTranslation } from 'react-i18next';

interface Props {
  adversaryPlayer?: MatchPlayer
}

const ButtonAdversaryHistory = ({adversaryPlayer}: Props) => {
  const { setRiotId } = useContext(SearchContext) as SearchContextValues

  const rollbar = useRollbar()
  const { t } = useTranslation()

  const adversaryRiotId = adversaryPlayer?.account?.riotId?.toString()

  if (!adversaryRiotId) {
    return null
  }

  const handleClick = () => {
    setRiotId(adversaryRiotId)

    rollbar.info("Adversary History Button Clicked", { adversaryPlayer: adversaryPlayer })
  }

  return ( 
    <>
      <Button 
        variant="contained"
        size={"large"}
        onClick={handleClick}
        endIcon={<SendIcon sx={{width: "20px", height: "20px"}} />}
        sx={{backgroundColor: theme => theme.palette.primary.main}}
      >
        <Typography fontWeight={"bold"} mr={0.5} fontSize={"10px"}>
          {t("HISTORY")} ({adversaryRiotId.split("#")[0]})
        </Typography>
      </Button>
    </>
  )
}

export default ButtonAdversaryHistory