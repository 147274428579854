import { AccordionDetails, Box, Stack } from "@mui/material"
import ButtonDeckCode from "./ButtonDeckCode"
import ButtonAdversaryHistory from "./ButtonAdversaryHistory"
import { Match, MatchPlayer } from "../graphql/__generated__/graphql"
import { useTranslation } from 'react-i18next';
import Chips from "./Chips"

interface Props {
  myPlayer: MatchPlayer
  adversaryPlayer?: MatchPlayer
  match: Match
}

const MatchAccordionDetails = ({myPlayer, adversaryPlayer, match}: Props) => {
  const whoStarted = match?.matchPlayers?.find(player => player.orderOfPlay === 0)
  const roundsCount = match?.totalTurnCount ? Math.ceil(match?.totalTurnCount / 2) : 0

  const { t } = useTranslation()
  let whoStartedName = whoStarted?.account?.gameName || t("The adversary")
  
  const chipsHashs = [
    { label: `${roundsCount} ${t("rounds", {count: roundsCount})}` },
    { label: `${whoStartedName} ${t("started playing")}` }
  ]

  return (
    <>
      <AccordionDetails sx={{paddingTop: 0}}>
        <Stack direction={"column"}>
          <Box display={"grid"} justifyContent={"center"} alignItems={"center"}>  
            <ButtonDeckCode player={myPlayer}/>
            <ButtonDeckCode player={adversaryPlayer}/>
            <ButtonAdversaryHistory adversaryPlayer={adversaryPlayer} />
          </Box>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mt={"20px"}>
            <Chips chipsHashs={chipsHashs} />
          </Box>
        </Stack>
      </AccordionDetails>
    </>
  )
}

export default MatchAccordionDetails
