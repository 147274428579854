import React, { useContext } from 'react';
import { Accordion } from '@mui/material'
import MatchAccordionSummary from './MatchAccordionSummary';
import MatchAcordionDetails from './MatchAcordionDetails';
import { Match } from '../graphql/__generated__/graphql';
import { SearchContext, SearchContextValues } from '../contexts/SearchContext';
import { useRollbar } from '@rollbar/react'; 

interface Props {
  match: Match
  expanded: string | false
  setExpanded: (any: string | false) => void
}

const MatchAccordion = ({match, expanded, setExpanded}: Props) => {
  const { riotId } = useContext(SearchContext) as SearchContextValues

  const rollbar = useRollbar();

  const myPlayer = match?.matchPlayers?.find(player => player.account?.riotId === riotId)
  const adversaryPlayer = match?.matchPlayers?.find(player => player.account?.riotId !== riotId)
  const won = myPlayer?.gameOutcome === "win"
  const matchId = match?.riotMatchId

  const onAccordionChange =
    (expandedMatchId: any) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? expandedMatchId : false)

      rollbar.info("Accordion Expanded Changed")
    }

  if (!myPlayer?.account) {
    return null
  }
  
  const red = 'rgba(255, 0, 0, 0.075)'
  const green = 'rgba(0, 255, 0, 0.075)'

  return (
    <>
      <Accordion 
        expanded={expanded === matchId} 
        onChange={onAccordionChange(matchId)}
        sx={{
          width: "100%", 
          background: won ? green : red
        }}
      >
        <MatchAccordionSummary 
          myPlayer={myPlayer}
          adversaryPlayer={adversaryPlayer} 
          match={match} 
        />
        <MatchAcordionDetails 
          myPlayer={myPlayer}
          adversaryPlayer={adversaryPlayer} 
          match={match} 
        />
      </Accordion>
    </>
  )
}

export default MatchAccordion
