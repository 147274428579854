import { Stack } from '@mui/material'
import { MatchPlayer } from '../graphql/__generated__/graphql'

interface Props {
  player?: MatchPlayer
}

const allowedFactions =
  ["bandlecity", "bilgewater", "demacia", "freljord", "ionia", "noxus", 
    "piltover", "runeterra", "shadowisles", "shurima", "targon"]

const allowedFactionsObject =
  allowedFactions.map(faction => {
    let src = null

    try {
      src = require(`../regions-icons/icon-${faction}.png`)
    } catch(e) { console.log(e) }
    
    return { faction: faction, src: src }
  })

const FactionImagesStack = ({player} : Props) => {
  const factionsImgs = 
    player?.factions?.map(faction => {
      const factionObject = allowedFactionsObject.find(object => 
        faction.toLowerCase().includes(object.faction)
      )

      const src = factionObject?.src

      if (!src) { 
        return null 
      }

      return <img style={{width: "20px", height: "20px"}} key={src} src={src} />
    })

  return (
    <>
      <Stack direction={"row"}>
        {factionsImgs}
      </Stack>
    </>
  )
}

export default FactionImagesStack;
