import { Stack } from "@mui/material"
import Chip from "./Chip"

interface Props {
  chipsHashs: { label?: string, show?: boolean, capitalize?: boolean }[]
  spacing?: number
}

const Chips = ({chipsHashs, spacing = 1}: Props) => {
  const chips = chipsHashs.map((chipHash, index) => 
    <Chip label={chipHash.label} show={chipHash.show} capitalize={chipHash.capitalize} key={index} />
  )

  return (
    <>
      <Stack direction={"row"} spacing={spacing}>
        {chips}
      </Stack>
    </>
  )
}

export default Chips
