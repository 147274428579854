import { createContext, useState } from "react";
 
export interface LoadingContextValues {
  loading: boolean
  setLoading: (any: boolean) => void
}

export const LoadingContext = createContext<LoadingContextValues | null>(null)

export const LoadingProvider = ({children}) => {
  const [loading, setLoading] = useState<boolean>(false)
 
  return (
    <LoadingContext.Provider value={{loading, setLoading}}>
        {children}
    </LoadingContext.Provider>
  );
};