import { Box } from "@mui/material"
import { MatchPlayer } from "../graphql/__generated__/graphql"

interface Props {
  adversaryPlayer?: MatchPlayer
  format?: string 
}

const GameFormatIcon = ({adversaryPlayer, format}: Props) => {
  if (!adversaryPlayer || !format) {
    return null
  }

  const standardIcon = require('../game-format-icons/queue_select_standard_toggle_active.png')
  const eternalIcon = require('../game-format-icons/queue_select_eternal_toggle_active.png')

  return ( 
    <>
      <Box display={"flex"} mr={"2.5px !important"}>
        <img 
          src={format === "eternal" ? eternalIcon : standardIcon }
          alt=""
          style={{width: "2em", height: "2em"}} 
        />
      </Box>
    </>
  )
}
 
export default GameFormatIcon