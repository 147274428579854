import { Button, Stack, Typography } from '@mui/material'
import { useContext } from 'react'
import { SearchContext, SearchContextValues } from '../contexts/SearchContext'
import { useRollbar  } from '@rollbar/react';
import { useTranslation } from 'react-i18next'; 

interface Props {
  show: boolean
}

const PlayerNotFound = ({show}: Props) => {
  const { region, setRegion, riotId, setRiotId } = useContext(SearchContext) as SearchContextValues

  const rollbar = useRollbar();
  const { t } = useTranslation()

  const handleExampleClick = () => {
    setRiotId("CITH#BR1")
    setRegion("americas")

    rollbar.info("Example Clicked", { region: region, riotId: riotId })
  }

  if (!show) {
    return null
  }

  rollbar.info("Player Not Found", { region: region, riotId: riotId })

  return (
    <>
      <Stack mt={4}>
        <Typography fontSize={"15px"} gutterBottom>
          {t("Player not found")}: <strong>{riotId}</strong> {t("in")} <strong>{t(region.toUpperCase())}</strong>
        </Typography>
        <Typography>
          {t("example")}:&nbsp;
          <Button 
            variant="text" 
            onClick={() => handleExampleClick()} 
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
                borderColor: "transparent",
              },
              color: 'blue',
              textTransform: "none",
              fontWeight: "bold",
              fontSize: "12.5px",
              padding: 0
            }}
          >
            CITH#BR1
          </Button>
        </Typography>
      </Stack>
    </>
  )
}

export default PlayerNotFound
