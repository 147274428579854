import { createTheme, ThemeProvider as Provider } from "@mui/material";

export const ThemeProvider = ({children}) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: 'rgb(105, 96, 83)'
      },
      background: {
        default: "#f7f7f7"
      }
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    }
  });
  
  return (
    <Provider theme={theme}>
        {children}
    </Provider>
  );
};