import { Provider , ErrorBoundary } from '@rollbar/react';

export const RollbarProvider = ({children}) => {
  const dev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    environment: dev ? "development" : "production"
  };
  
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </Provider>
  );
};