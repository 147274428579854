import "./App.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HistoryPage from "./pages/HistoryPage";
import './i18n';
import { useRollbar } from '@rollbar/react'; 
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation()
  const rollbar = useRollbar()
  
  rollbar.info(i18n.language)

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HistoryPage />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App;
