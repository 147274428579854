import { Box } from '@mui/material'
import { Match } from '../graphql/__generated__/graphql'
import NoRecentMatches from './NoRecentMatches'

interface Props {
  matches?: Match[]
  show: boolean
}

const MatchesInfo = ({matches, show}: Props) => {
  if (!show) {
    return null
  }

  return (
    <>
      <Box mt={2}> 
        <NoRecentMatches matches={matches} />
      </Box>
    </>
  )
}

export default MatchesInfo
