import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box p={2} mt={"auto"} sx={{backgroundColor: theme => theme.palette.grey[200]}}>
        <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} >
          <Typography>
            {t("Tell me what you think")}&nbsp;
          </Typography> 
          <Typography fontWeight={"bold"} mt={"2.5px"}>
            iagocithgtr@gmail.com
          </Typography>
        </Stack>
      </Box>
    </>
  )
}

export default Footer
