import { Box, Stack } from "@mui/material"
import Search from "./Search"
import PlayerNotFound from "./PlayerNotFound"
import MatchesInfo from "./MatchesInfo"
import MatchesList from "./MatchesList"
import { useContext, useEffect } from "react"
import { SearchContext, SearchContextValues } from "../contexts/SearchContext"
import { useGetAccountLazyQuery } from "../graphql/__generated__/graphql"
import { LoadingContext, LoadingContextValues } from "../contexts/LoadingContext"
import { useRollbar } from '@rollbar/react'; 

const MainContainer = () => {
  const { region, riotId } = useContext(SearchContext) as SearchContextValues
  const { loading, setLoading } = useContext(LoadingContext) as LoadingContextValues

  const [ getAccount, { loading: accountLoading, data: accountData, error: accountError } ] = useGetAccountLazyQuery()

  const rollbar = useRollbar()

  const account = accountData?.account
  const matches = account?.matches || undefined

  const showMatchesList = !loading && account && matches && matches.length > 0
  const showAccountNotFound = accountData && !account
  const showMatchesInfo = account && matches 

  if (region === "americas" && riotId === "") {
    rollbar.info("Acessing website in inicial state");
  }

  useEffect(() => {
    if (region && riotId) {
      console.log("--- Fetching Account")
      window.history.replaceState(null, '', `?region=${region}&riot-id=${riotId}`)
      getAccount({variables: { region: region, riotId: riotId }})
    
      rollbar.info("Fetching Account", { region: region, riotId: riotId });
    }
  }, [region, riotId]);

  useEffect(() => {
    setLoading(accountLoading)
  }, [accountLoading])

  useEffect(() => {
    if (account) {
      console.log("--- Account Fetched")
    
      rollbar.info("Fetched Account", { account: account });
    }
  }, [account]);

  useEffect(() => {
    if (accountError) {
      rollbar.error("[Error] Fetching Account", { accountError: accountError });
    }
  }, [accountError])

  return (
    <>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{py: 4, px: {xs: 2, md: 4}}}>
        <Stack direction={"column"} sx={{width: {xs: "100%", md: "75%", lg: "50%"}}}>
          <Search />
          <MatchesList matches={matches} show={!!showMatchesList} />
          <PlayerNotFound show={!!showAccountNotFound} />
          <MatchesInfo matches={matches} show={!!showMatchesInfo} /> 
        </Stack>
      </Box>
    </>
  )
}

export default MainContainer
