import { useState } from "react";
import MatchAccordion from "./MatchAccordion";
import { Stack } from "@mui/material";
import { Match } from "../graphql/__generated__/graphql";

interface Props {
  matches: Match[] | undefined
	show: boolean
}

const MatchesList = ({matches, show}: Props) => {
  const [expanded, setExpanded] = useState<string | false>(false);

	if (!show) { 
		return null
	}

	const accordions = matches?.map(match =>
		<MatchAccordion
			match={match}
			expanded={expanded}
			setExpanded={setExpanded}
			key={match?.riotMatchId}
		/>
	)

	return (
		<>
			<Stack direction="column" justifyContent={"center"} alignItems="center" mt={4}>
				{accordions}
			</Stack>
		</>
	)
}

export default MatchesList;
