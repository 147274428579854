import { Typography } from "@mui/material"
import { ComponentPropsWithRef } from "react"

const TypographySecondary = ({children, ...props}: ComponentPropsWithRef<typeof Typography>) => {
  return (
    <>
      <Typography color="text.secondary" fontStyle="italic" {...props}>
        {children}
      </Typography>
    </>
  )
}

export default TypographySecondary