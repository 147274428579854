import { ApolloClient, ApolloProvider as Provider, InMemoryCache } from '@apollo/client';

export const ApolloProvider = ({children}) => {
  const dev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

  const apolloClient = new ApolloClient({
    uri: dev ? process.env.REACT_APP_DEV_DEV_URL : process.env.REACT_APP_PROD_API_URL,
    cache: new InMemoryCache(),
  });
  
  return (
    <Provider client={apolloClient}>
      {children}
    </Provider>
  );
};