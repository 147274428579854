import {default as MaterialAppBar} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { LinearProgress } from '@mui/material';
import { useContext } from 'react';
import { LoadingContext, LoadingContextValues } from '../contexts/LoadingContext';

export default function AppBar() {
  const { loading } = useContext(LoadingContext) as LoadingContextValues

  const progress = loading &&
    <LinearProgress 
      sx={{
        marginTop: "-4px",
        backgroundColor: theme => theme.palette.background.default,
        "& .MuiLinearProgress-bar": {
          backgroundColor: theme => theme.palette.primary.main
        }
      }} 
    />

  return (
    <>
      <MaterialAppBar position="static" sx={{backgroundColor: theme => theme.palette.primary.main}}>
        <Toolbar variant="dense">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Runeterra History
          </Typography>
        </Toolbar>

        {progress}
      </MaterialAppBar>
    </>
  );
}
