import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  "en-US": {
    translation: {
      "Inform the Riot ID of the player": "Inform the <strong>Riot ID</strong> of the player",
      "rounds_one": "round",
      "rounds_other": "rounds",
      "ThePathOfChampions": "The Path Of Champions"
    }
  },
  "pt-BR": {
    translation: {
      "Search": "Buscar",
      "Inform the Riot ID of the player": "Informe o <strong>Riot ID</strong> do jogador",
      "example": "exemplo",
      "Tell me what you think": "Me conte o que achou",
      "DECK CODE COPIED": "CÓDIGO DO DECK COPIADO",
      "DECK CODE": "CÓDIGO DO DECK",
      "HISTORY": "HISTÓRICO",
      "started playing": "começou jogando",
      "rounds_one": "rodada",
      "rounds_other": "rodadas",
      "standard": "Padrão",
      "eternal": "eterno",
      "Ranked": "Rankedada",
      "The adversary": "O adversário",
      "ThePathOfChampions": "O Caminho Dos Campeões",
      "This player has no recent matches": "Esse jogador não tem partidas recentes",
      "Player not found": "Jogador não encontrado",
      "in": "em",
      "AMERICAS": "AMÉRICAS",
      "EUROPE": "EUROPA",
      "AI": "IA"
    }
  }
};

const lang = navigator.language || navigator.userLanguage

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: lang === "pt-BR" ? lang : "en-US",
  });

  export default i18n;
