import { Button, FormControl, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Send';
import TypographySecondary from './TypographySecondary';
import { SearchContext, SearchContextValues } from '../contexts/SearchContext';
import { useRollbar  } from '@rollbar/react'; 
import { useTranslation, Trans } from 'react-i18next';

const Search = () => {
  const { region, setRegion, riotId, setRiotId } = useContext(SearchContext) as SearchContextValues

  const [search, setSearch] = useState<string>("")
  const [localRegion, setLocalRegion] = useState<string>(region)

  const rollbar = useRollbar()
  const { t } = useTranslation();

  useEffect(() => {
    setSearch(riotId)
    setLocalRegion(region)
  }, [riotId, region])

  const handleSearch = () => {
    setRiotId(search.trim()) 
    setRegion(localRegion)

    rollbar.info("Search Button Clicked", { region: localRegion, riotId: search.trim() })
  }

  const handleRegionChanged = (event: SelectChangeEvent) => { 
    setLocalRegion(event.target.value)

    rollbar.info("Region Select Changed", { region: event.target.value })
  }

  return (
    <>
      <Stack direction={"column"}>
        <Typography fontSize={"15px"} fontWeight={"bold"} gutterBottom>
          {t("Search")} 
        </Typography>
        <TypographySecondary fontStyle={"normal"} mb={2}>
          {<Trans i18nKey="Inform the Riot ID of the player" components={{ strong: <strong /> }} />}
        </TypographySecondary>
        <Stack direction="row" spacing={1}>
          <FormControl sx={{minWidth: "auto" }} size="small">
            <Select value={localRegion} onChange={handleRegionChanged}>
              <MenuItem value="americas">{t("AMERICAS")}</MenuItem>
              <MenuItem value="europe">{t("EUROPE")}</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            fullWidth
            placeholder={`${t("example")}: CITH#BR1`}
            value={search}
            onKeyDown={(e: any) => {
              if (e.code === "Enter") {
                e.target.blur();
                handleSearch()
              }
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearch(event.target.value)
            }}
          />
          <Button 
            variant="contained"
            endIcon={<SearchIcon/>}
            className="ButtonWithoutText"
            onClick={() => handleSearch()}
            sx={{backgroundColor: theme => theme.palette.primary.main}}
          />
        </Stack>
      </Stack> 
    </>
  )
}

export default Search
