import { createContext, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
 
export interface SearchContextValues {
  region: string
  setRegion: (any: string) => void
  riotId: string
  setRiotId: (any: string) => void
}

export const SearchContext = createContext<SearchContextValues | null>(null)

export const SearchProvider = ({children}) => {
  const [region, setRegion] = useState<string>("americas")
  const [riotId, setRiotId] = useState<string>("")

  // url params
  const [searchParams] = useSearchParams()
  const { hash: hashParam } = useLocation();

  // just ensures players comming from url (example when someone share their history url to someone)
  useEffect(() => {
    const paramsRegion = searchParams.get("region")
    const paramsRiotId = searchParams.get("riot-id")

    if (paramsRegion) {
      setRegion(paramsRegion)
    }

    if (paramsRiotId) {
      setRiotId(paramsRiotId + hashParam)
    }
  }, [])
 
  return (
    <SearchContext.Provider value={{ region, setRegion, riotId, setRiotId }}>
        {children}
    </SearchContext.Provider>
  );
};