import {Stack} from "@mui/material";
import AppBar from "../components/AppBar";
import Footer from "../components/Footer";
import MainContainer from "../components/MainContainer";
import { SearchProvider } from "../contexts/SearchContext";
import { LoadingProvider } from "../contexts/LoadingContext";

const HistoryPage = () => {
  return (
    <>
      <SearchProvider>
        <LoadingProvider>
          <Stack>
            <AppBar />

            <MainContainer />
            
            <Footer/>
          </Stack>   
        </LoadingProvider>
      </SearchProvider>
    </>
  )
}

export default HistoryPage;