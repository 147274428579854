import { Button, Typography } from "@mui/material"
import FactionImagesStack from "./FactionImagesStack"
import { useSnackbar, VariantType } from "notistack";
import { MatchPlayer } from "../graphql/__generated__/graphql";
import { useRollbar  } from '@rollbar/react'; 
import { useTranslation } from 'react-i18next';

interface Props {
  player?: MatchPlayer
}

const ButtonDeckCode = ({player}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const rollbar = useRollbar();
  const { t } = useTranslation();
  
  const deckCode = player?.deckCode
  if (!deckCode) {
    return null
  }

  const handleClick = () => {
    if (deckCode) {                    
      navigator.clipboard.writeText(deckCode)

      const variant: VariantType = "default"

      enqueueSnackbar(
        t("DECK CODE COPIED"), 
        { 
          variant, 
          hideIconVariant: true,
          preventDuplicate: true, 
          autoHideDuration: 2000, 
          anchorOrigin: { vertical: "top", horizontal: "center" },
          key: deckCode,
        }
      );

      rollbar.info("Deck Code Copied", { player: player })
    }
  }

  return (
    <>
      <Button 
        variant="contained"
        size={"large"}
        onClick={handleClick}
        sx={{backgroundColor: theme => theme.palette.primary.main, width: "100%", marginBottom: "8px"}}
      >
        <Typography fontWeight={"bold"} mr={0.5} fontSize={"10px"}>
          {t("DECK CODE")} ({player?.account?.gameName})
        </Typography>

        <FactionImagesStack player={player}/>
      </Button>
    </>
  )
}
 
export default ButtonDeckCode